@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'gothic';
  src: local('Gothic'), url(./fonts/GOTHIC.TTF) format('truetype');
  };

@font-face {
  font-family: 'lavish';
  src: local('Lavish'), url(./fonts/LavishlyYours-Regular.ttf) format('truetype');
  };